(function() {
  var app = angular.module('priorWeb');

  /**
   * @typedef ILoginControllerScope
   * @property {() => void} cancel
   * @property {string[]} domains
   * @property {() => void} facebookLogin
   * @property {ng.IFormController} form
   * @property {string} formClass
   * @property {{ [param: string]: any }} formData
   * @property {() => string[]} getEmailDomains
   * @property {() => boolean} isLogin
   * @property {() => boolean} isResetPassword
   * @property {() => void} login
   * @property {() => void} register
   * @property {() => void} requestPasswordReset
   * @property {(isLogin: boolean) => void} setLogin
   * @property {(isResetPassword: boolean) => void} setResetPassword
   * @property {(suggestion: string) => void} setSuggestion
   * @property {(suggestion: string) => void} setSuggestionReset
   * @property {array} suggestions
   */

  app.controller('LoginController', [
    '$scope', '$translate', '$uibModalInstance', 'Alert', 'CurrentOrder', 'CurrentUser', 'FacebookLogin',
    'FacebookLoginErrorEnum', 'User', 'trackGAViews', 'GoogleAnalytics',
    /**
     * @param {ILoginControllerScope & ng.IScope} $scope
     * @param {ng.translate.ITranslateService} $translate
     * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
     * @param {Alert} Alert
     * @param {CurrentUser} CurrentUser
     * @param {typeof FacebookLoginErrorEnum} FacebookLoginErrorEnum
     * @param {UserResource} User
     * @param {string[]} trackGAViews
     * @param {GoogleAnalyticsWrapper} googleAnalytics
     */
    function(
      $scope, $translate, $uibModalInstance, Alert, CurrentOrder, CurrentUser, FacebookLogin,
      FacebookLoginErrorEnum, User, trackGAViews, googleAnalytics
    ) {
      var isLogin = true;
      var isResetPassword = false;

      /**
       * @param {ng.IHttpResponse<{ code: number, message: string }>} result
       */
      function handleRegistrationError(result) {
        switch (result.status) {
        case 403:
          Alert.show(result.data.message);
          break;
        default:
          Alert.show('Unknown error!');
          break;
        }
      }

      /**
       * @param {ng.IHttpResponse} result
       */
      function handleRequestResetPasswordError(result) {
        var message;
        switch (result.status) {
        case 404:
          message = 'LOGIN_MODAL.USER_NOT_FOUND_ERROR';
          break;
        default:
          message = 'LOGIN_MODAL.UNKNOWN_ERROR';
          break;
        }

        $translate(message).then(
          /**
           * @param {string} message
           */
          function(message) {
            Alert.show(message);
          }
        );
      }

      function handleRequestResetPasswordSuccess() {
        $translate('LOGIN_MODAL.RESET_PASSWORD_LINK_SENT').then(
          /**
           * @param {string} message
           */
          function (message) {
            Alert.show(message, 'ALERT_MODAL.SUCCESS_TITLE');
          }
        );
        $uibModalInstance.close();
      }

      /**
       * @param {UserModel} user
       * @param {ng.IHttpHeadersGetter} __responseHeaders__
       */
      function handleUserRegistered(user, __responseHeaders__) {
        CurrentUser.set(user);
        $uibModalInstance.close();
      }

      function login() {
        User.authenticate(
          {},
          $scope.formData,
          /**
           * @param {UserModel} user
           * @param {ng.IHttpHeadersGetter} __headers__
           */
          function authenticationSuccess(user, __headers__) {
            CurrentUser.set(user);
            $uibModalInstance.close();
            googleAnalytics.setUserId(user.getId());
          },
          /**
           * @param {ng.IHttpResponse<{ code: number, message: string }>} httpResponse
           */
          function authenticationError(httpResponse) {
            Alert.show(httpResponse.data.message);
          }
        );
      }

      function register() {
        User.register($scope.formData, handleUserRegistered, handleRegistrationError);
      }

      function requestPasswordReset() {
        User.requestPasswordReset(
          $scope.formData,
          handleRequestResetPasswordSuccess,
          handleRequestResetPasswordError
        );
      }

      $scope.$on('$viewContentLoaded', function(){
        trackGAViews.forEach(function(propertyId) {
          googleAnalytics.trackView(propertyId, 'login');
        });
      });

      $scope.cancel = function() {
        $uibModalInstance.dismiss({
          code: 4201
        });
      };

      $scope.facebookLogin = function() {
        FacebookLogin.login().then(
          function facebookLoginSuccess() {
            if (CurrentUser.isSet()) {
              $uibModalInstance.close();
              googleAnalytics.setUserId(CurrentUser.get().getId());
            }
          },
          /**
           * @param {any} error
           */
          function facebookLoginError(error) {
            if (error && error.errorCode != 4201 && error.errorMessage != 'User cancelled dialog') {
              var message;
              if (error.errorCode === FacebookLoginErrorEnum.MISSING_EMAIL_PERMISSION) {
                message = $translate.instant('LOGIN_MODAL.FACEBOOK_LOGIN_ERROR_MODAL_MISSING_EMAIL_PERMISSION_MESSAGE');
              } else {
                message = error.errorMessage ? error.errorMessage :
                  'No pudimos conectar con Facebook. ¿Podrías volver a intentarlo?';
              }
              Alert.show(message);
            }
          }
        );
      };

      $scope.formData = {
        User: {
          email: null,
          password: null
        },
        accepted: false
      };

      $scope.isInstagram = function() {
        return window.navigator.userAgent.indexOf('Instagram') > -1;
      };

      $scope.isLogin = function() {
        return isLogin;
      };

      $scope.isResetPassword = function() {
        return isResetPassword;
      };

      $scope.login = function() {
        $scope.formClass = 'ng-submitted';
        if ($scope.form.$valid) {
          login();
        }
      };

      $scope.register = function() {
        $scope.formClass = 'ng-submitted';
        if ($scope.form.$valid) {
          register();
        } else if (!$scope.formData.accepted) {
          Alert.show($translate.instant('LOGIN_MODAL.TERMS_NOT_ACCEPTED_ERROR_MESSAGE'));
        }
      };

      $scope.requestPasswordReset = function() {
        $scope.formClass = 'ng-submitted';
        if ($scope.form.$valid) {
          requestPasswordReset();
        }
      };

      $scope.setLogin = function(newValue) {
        isLogin = newValue;
      };

      $scope.setResetPassword = function(newValue) {
        if (newValue) {
          $scope.formData = {
            email: null,
            restaurantId: CurrentOrder.getRestaurantId()
          };
        } else {
          $scope.formData = {
            User: {
              email: null,
              password: null
            }
          };
        }

        isResetPassword = newValue;
      };

      $scope.setSuggestion = function(suggestion) {
        $scope.formData.User.email = suggestion
      }

      $scope.setSuggestionReset = function(suggestion) {
        $scope.formData.email = suggestion
      }
    }
  ]);
})();
